.hero {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-image: url("#{$path-icons}/bg-texture.png");
  }

  &__icons {
    position: absolute;
    z-index: 0;
    display: none;
  }

  &__title {
    margin-bottom: 0;
  }

  &__title-image {
    display: inline-block;
    position: absolute;
    top: 70px;
    right: -90px;
    max-width: 165px;
  }

  &.paragraph {
    padding: 170px 0 170px;
    min-height: 75vh;
    display: flex;
    align-items: center;
  }

  &__image-top {
    position: absolute;
    top: -62px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 105px;
    z-index: 1;
    display: block;
  }

  &__image-top-left {
    top: 0;
    left: 280px;
  }

  &__image-inner-1 {
    max-width: 126px;
    left: -15px;
    top: 10px;
    transform: rotate(-40deg);
    display: block;
  }

  &__image-outer-2 {
    left: -30px;
    bottom: 110px;
    z-index: 2;
    display: block;
  }

  &__image-outer-3 {
    left: 0;
    bottom: 60px;
    display: block;
  }

  &__image-bottom {
    position: absolute;

    &--left {
      bottom: -60px;
      left: 0;
      max-width: 161px;
    }

    &--right {
      bottom: -160px;
      right: 20px;
      max-width: 136px;
    }
  }

  &--landing {
    overflow: visible;

    &::before {
      display: none;
    }

    .hero {
      &__title {
        margin-bottom: $spacing-32;
      }

      &__description {
        max-width: 858px;
      }

      &__sticker-top,
      &__sticker-bottom,
      &__sticker-outer {
        position: absolute;
      }

      &__sticker-top {
        top: 30%;
        right: 0;
        transform: translateY(-50%);
        width: 80px;
      }

      &__sticker-bottom {
        right: 100px;
      }

      &__sticker-outer {
        display: none;
        top: 75%;
        width: 170px;
        right: -50px;
        transform: translateY(-50%);
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .hero {
    &__title {
      margin-bottom: 60px;
      position: relative;
      z-index: 1;
    }

    &__title-image {
      z-index: -1;
    }

    &.paragraph {
      padding: 220px 0;
      height: 100%;
    }

    &__icons {
      display: block;
    }

    &__image-inner-1 {
      left: 15px;
      top: 40px;
    }

    &__image-outer-1 {
      display: none;
      left: -60px;
      top: 50%;
      transform: translateY(-50%);
    }

    &__image-outer-2 {
      left: 40px;
      bottom: 190px;
    }

    &__image-outer-3 {
      left: 0;
      bottom: 150px;
    }

    &__image-outer-4 {
      left: 360px;
      bottom: -80px;
      z-index: 0;
    }

    &__image-outer-5 {
      right: 460px;
      bottom: 80px;
    }

    &__image-outer-6 {
      bottom: -140px;
      right: 190px;
      z-index: 0;
    }

    &__image-outer-7 {
      display: none;
      right: 0;
      top: 120px;
    }

    &__image-top {
      top: -72px;
      left: 70%;
      max-width: 228px;
    }

    &__image-bottom {
      position: absolute;

      &--left {
        bottom: 0;
        left: 32px;
        max-width: 314px;
      }

      &--right {
        bottom: -130px;
        right: 62px;
        max-width: 142px;
      }
    }

    &--landing {
      .hero__title {
        font-size: $font-size-lgx;
      }

      .hero__description {
        margin-bottom: 60px;
      }

      .hero__sticker-top {
        right: 30%;
        width: 183px;
      }

      .hero__sticker-outer {
        display: block;
        top: 50%;
        width: 280px;
      }

      &.paragraph {
        padding-bottom: 88px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .hero {
    &__eye {
      position: relative;
      width: 150px;
      display: inline-block;

      &::before {
        content: "";
        position: absolute;
        background-image: url("#{$path-icons}/eye.svg");
        background-position: center;
        background-repeat: no-repeat;
        width: 210px;
        height: 169px;
        top: -30px;
        left: -24px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .hero {
    &__content-wrapper {
      margin-left: 68px;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .hero {
    &__title-image {
      top: -17px;
      right: 0;
    }

    &__image-outer-1,
    &__image-outer-7 {
      display: block;
    }

    &--landing {
      .hero__title {
        font-size: $font-size-heading-md;
      }
    }
  }
}

@include media-breakpoint-up(xlg) {
  .hero {
    &__eye {
      &::before {
        top: -25px;
        left: -27px;
      }
    }
  }
}
