header {
  position: absolute;
  background-color: transparent;
  padding: 20px 0;
  width: 100%;
  transition: transform $transition-easing $transition-speed,
    background-color $transition-easing $transition-speed;
  z-index: 5;

  &.up {
    background-color: $pink;
    position: fixed;
    transform: translateY(0);
  }

  &.header--top {
    background-color: transparent;
  }

  &.isDown {
    position: fixed;
    transform: translateY(-100%);
  }

  .logo {
    max-width: 122px;
  }

  .header__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
