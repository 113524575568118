.list-section {
  position: relative;

  .container {
    position: relative;
  }

  &__title {
    margin-bottom: 44px;
  }

  &__content {
    margin-bottom: 78px;
  }

  &__image-top {
    position: absolute;
    top: -190px;
    right: 0;
    max-width: 84px;
  }

  &__image-bottom {
    position: absolute;
    bottom: -180px;
    left: 0;
    max-width: 110px;
  }
}

@include media-breakpoint-up(md) {
  .list-section {
    &__title {
      margin-bottom: 88px;
    }

    &__content {
      max-width: 390px;
      margin-right: auto;
      margin-left: auto;
    }

    &__content-text {
      font-size: $font-size-sub;
    }

    &__image-top {
      top: -238px;
      right: -30px;
      max-width: 208px;
    }

    &__image-bottom {
      bottom: -253px;
      max-width: 180px;
    }
  }
}
