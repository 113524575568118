.testimonial {
  position: relative;

  &__company {
    font-size: $font-size-xxs;
  }

  &__name {
    margin-bottom: 4px;
  }

  &__image {
    width: 80px;
    height: 80px;
    margin: 40px auto 24px;
    border-radius: 100%;
    overflow: hidden;
  }
}

@include media-breakpoint-up(md) {
  .testimonial {
    &.paragraph--white {
      padding-top: 80px;
    }

    &__company {
      font-size: $font-size-sub;
    }
  }
}
