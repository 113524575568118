.card-column {
  &__content {
    display: flex;
    border-radius: $radius-sm;
    box-shadow: $card-shadow--columns;
    margin-bottom: 40px;
    flex-direction: column;
    color: $black;
  }

  &__information {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  &__even {
    background-color: $yellow;
  }

  &__odd {
    background-color: $orangeOnBlack;
  }
}

.cards-column-section {
  position: relative;

  &__top-section {
    margin-bottom: 60px;
  }

  .container {
    position: relative;
  }

  &__title {
    margin-bottom: 24px;
  }

  &__image-top,
  &__image-right,
  &__image-bottom {
    position: absolute;
    display: none;
  }

  &__image-top {
    max-width: 50px;
    top: -120px;
    left: 0;
  }

  &__image-right {
    display: none;
  }

  &__image-bottom {
    max-width: 100px;
    bottom: -85px;
    left: 0;
    transform: rotate(26deg);
  }
}

@include media-breakpoint-up(md) {
  .card-column {
    &__content {
      flex-direction: row;
      margin-bottom: 80px;
    }

    &__text {
      font-size: $font-size-sub;
    }

    &__information {
      padding: 60px;
    }
  }

  .cards-column-section {
    &__top-section {
      margin-bottom: 120px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .cards-column-section {
    &.paragraph {
      padding: 170px 0;
    }

    &__image-top,
    &__image-right,
    &__image-bottom {
      display: block;
    }

    &__image-top {
      max-width: 129px;
      top: 365px;
      left: 32%;
      transform: rotate(37.8deg);
    }

    &__image-right {
      display: block;
      max-width: 230px;
      right: 100px;
      top: 220px;
    }

    &__image-bottom {
      max-width: 106px;
      bottom: -82px;
      left: 70px;
    }
  }

  .card-column {
    &__inner {
      display: flex;
    }

    &__even {
      margin-right: 60px;
    }
  }
}
