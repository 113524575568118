.accordion-item {
  &--no-body {
    .accordion-button {
      cursor: default;

      &:after {
        display: none;
      }
    }
  }
}

// BS classes
.accordion {
  margin-top: 32px;

  &-item {
    border-radius: 70px;
    background-color: $bg-grey;
    padding: 14px 26px;
    margin-bottom: 20px;
    position: relative;

    &:first-of-type {
      border-top-left-radius: 70px;
      border-top-right-radius: 70px;
    }

    &:last-of-type {
      border-bottom-right-radius: 70px;
      border-bottom-left-radius: 70px;
      margin-bottom: 0;

      &::after {
        display: none;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -21px;
      left: 55px;
      width: 2px;
      height: 20px;
      background-color: rgba($white, 0.4);
    }
  }

  &-header {
    font-size: $font-size-sub;
  }

  &-button {
    background-color: transparent;
    color: $white;
    padding: 0;
    font-size: $font-size-sub;
    display: block;

    &::after {
      background-image: url("#{$path-icons}/chevron.svg");
    }

    &:not(.collapsed) {
      background-color: transparent;
      color: $white;

      &::after {
        background-image: url("#{$path-icons}/chevron.svg");
      }
    }

    span {
      font-weight: 800;
      margin-bottom: 8px;
      display: block;
    }
  }
}

@include media-breakpoint-up(md) {
  .accordion {
    &-button {
      display: flex;

      span {
        margin-right: 30px;
        margin-bottom: 0;
        display: inline-block;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .accordion {
    margin-top: 0;

    &-button {
      display: flex;
    }
  }

  .text-accordion__content {
    max-width: 800px;
    margin-left: 68px;

    .btn {
      margin-top: 24px;
    }
  }
}
