.text-media {
  position: relative;
  &:not(.paragraph--black) {
    background-color: $light-grey;
  }

  .col {
    position: relative;
  }

  &__image-wrapper {
    height: 100%;
  }

  &__image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    position: relative;
    margin-right: -1.5rem;
    margin-left: -1.5rem;
    min-height: 341px;
  }

  &__sticker {
    display: none;
    position: absolute;
    bottom: -80px;
    right: 175px;
    z-index: 1;
  }
}

@include media-breakpoint-up(xl) {
  .text-media {
    &__content {
      max-width: 800px;
      margin-left: 68px;
    }

    &__title {
      margin-bottom: 40px;
    }

    &__image {
      position: absolute;
      width: 100%;
      top: 0;
      right: initial;
      margin-right: auto;
      margin-left: auto;
    }

    &__sticker {
      display: block;
    }

    &__sticker-left {
      position: absolute;
      bottom: 10px;
      right: 300px;
      z-index: 1;
      width: 197px;
    }

    &__sticker-right {
      position: absolute;
      bottom: -10px;
      right: 0;
      z-index: 1;
      width: 228px;
    }

    &__copy {
      font-size: 27px;
    }

    .btn {
      margin-top: 24px;
    }
  }
}
