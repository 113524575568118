.card {
  border-radius: $radius-sm;
  background-color: $white;
  margin-bottom: 40px;
  margin-right: 30px;
  box-shadow: $card-shadow--simple;

  &__header {
    border-top-left-radius: $radius-sm;
    border-top-right-radius: $radius-sm;
  }

  &__image-wrapper {
    display: flex;
    align-items: center;
    min-height: 230px;
    max-width: 307px;
    margin: 0 auto;
    padding: 43px 60px;
  }

  &__body {
    padding: 40px 32px;
    color: $black;
    text-align: center;
  }

  &__motion-list {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__motion-list-item {
    width: 33.33%;
    display: inline-flex;

    &:last-child {
      .card {
        margin-right: 0;
      }
    }
  }
}

.cards-display {
  .container-fluid {
    position: relative;
  }

  &__content {
    margin-right: -1.5rem;
  }

  &__image-top,
  &__image-right {
    position: absolute;
  }

  &__image-top {
    max-width: 50px;
    top: -120px;
    left: 0;
  }

  &__image-right {
    display: none;
  }

  &__title {
    margin-bottom: 40px;
  }
}

@include media-breakpoint-up(md) {
  .card {
    margin-bottom: 80px;

    &__text {
      font-size: $font-size-sub;
    }
  }

  .cards-display {
    &__image-top {
      max-width: 129px;
      top: -200px;
    }

    &__image-right {
      display: block;
      z-index: 1;
      max-width: 197px;
      right: 0;
      top: 20px;
    }

    &__content {
      margin-right: auto;
    }

    &__title {
      margin-bottom: 80px;
    }
  }
}
