.paragraph {
  padding: 64px 0;

  &--pink {
    background-color: $pink;
  }

  &--black {
    background-color: $black;
    color: $white;

    & + & {
      padding-top: 0;
    }
  }

  &--off-white {
    background-color: $off-white;

    & ~ .contact {
      padding-top: 0;

      .contact__title {
        margin-top: 0;
      }
    }
  }

  &--light-grey {
    background-color: $light-grey;
  }
}

@include media-breakpoint-up(md) {
  .paragraph {
    padding: 152px 0;

    &.text-accordion {
      padding-bottom: 240px;
    }
  }
}
