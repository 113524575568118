.contact {
  position: relative;

  &__image {
    margin-bottom: 24px;
  }

  &__image-top {
    display: none;
  }

  &__image-bottom {
    display: none;
  }

  &__title {
    margin-top: 48px;
    margin-bottom: 20px;
  }

  &__text {
    margin-bottom: 40px;
  }

  &__content {
    position: relative;
  }

  &__icon-top {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -105px;
    width: 90px;
  }

  &__section-icon-top {
    position: absolute;
    top: -83px;
    width: 70px;
    right: 40px;
  }

  &__section-icon-bottom {
    position: absolute;
    width: 60px;
    bottom: -50px;
    left: -10px;
  }
}

@include media-breakpoint-up(md) {
  .contact {
    &__image {
      margin-bottom: 40px;
    }

    &__image-top {
      display: block;
      position: absolute;
      top: -80px;
      left: 55px;
      max-width: 180px;
    }

    &__content {
      margin: 0 auto;
      max-width: 944px;
    }

    &__title {
      margin-bottom: 40px;
    }

    &__text {
      font-size: 27px;
      margin-bottom: 80px;
    }

    &__image-bottom {
      display: block;
      position: absolute;
      bottom: 0;
      left: 22px;
      max-width: 100px;
    }

    &__section-icon-top {
      width: 130px;
    }

    &__section-icon-bottom {
      bottom: 50px;
      width: 118px;
      left: 20px;
    }

    &__icon-top {
      width: 171px;
      top: -130px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .contact {
    &__image-bottom {
      bottom: 100px;
      max-width: 197px;
    }
  }
}
