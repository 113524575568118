body {
  font-size: 16px;
  font-weight: normal;
  position: relative;
  margin: 0;
  height: 100%;
  width: 100%;
  color: $black;
  overflow-x: hidden;
}

.text-link {
  color: $white;
  text-decoration: none;
  transition: color $transition-easing $transition-speed;

  &--dark {
    color: $black;
  }

  &:active,
  &:hover {
    color: $pink;
    text-decoration: underline;
  }
}

ul {
  padding-left: 0;
  list-style: none;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  padding-right: var(--bs-gutter-x, 1.5rem);
  padding-left: var(--bs-gutter-x, 1.5rem);
}

img {
  width: 100%;
  height: auto;
}

svg {
  width: 100%;
}

.h1,
h1 {
  font-family: $font-title;
  font-weight: 900;
  font-size: $font-size-sm;
  line-height: $line-height;
  word-break: break-word;
}

.h2,
h2 {
  font-family: $font-title;
  font-weight: 800;
  font-size: $font-size-sm;
  line-height: $line-height;
  word-break: break-word;
}

.h3,
h3 {
  font-family: $font-title;
  font-weight: 800;
  font-size: $font-size-xsm;
  line-height: $line-height-lg;
}

.h4,
h4 {
  font-family: $font-title;
  font-weight: 800;
  font-size: $font-size-xsm;
}

.h5,
h5 {
  font-size: $font-size-xsm;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5 {
  text-transform: uppercase;
  letter-spacing: $letter-spacing;
}

.text-normal-case {
  text-transform: none;
}

@include media-breakpoint-up(md) {
  .h1,
  h1 {
    font-size: $font-size-heading-md;
    line-height: $line-height-md;
  }

  .h2,
  h2 {
    font-size: $font-alt-lgx;
    line-height: $line-height-md;
  }

  .h2--alt {
    font-size: $font-alt-md;
  }

  .h3,
  h3 {
    font-size: $font-size-lg;
  }

  .h4,
  h4 {
    font-size: $font-size-md;
  }

  p,
  .text-link {
    font-size: $font-size-body;
  }
}

@include media-breakpoint-up(lg) {
  .h1,
  h1 {
    font-size: $font-size-heading-lg;
  }
}

@include media-breakpoint-up(xlg) {
  .h1,
  h1 {
    font-size: $font-size-xxl;
  }

  .h2,
  h2 {
    font-size: $font-size-xl;
  }
}
