.styles_ticker__-0UvZ {
  background-color: $pink !important;
}

.styles_ticker-list__1JKu- {
  align-items: center;
  justify-content: space-around;
  width: auto !important;
}

.ticker-tape {
  position: relative;
  z-index: 1;

  &__item {
    margin-left: 30px;
    padding: 16px;

    img {
      margin: 0;
      width: auto;
      height: 100%;
      min-height: 16px;
    }
  }
}

@include media-breakpoint-up(md) {
  .ticker-tape {
    &__item {
      padding: 24px;
  
      img {
        min-height: 24px;
      }
    }
  }
}
