// colours
$pink: #fcb1d8;
$purple: #977bff;
$orange: #fe5f2a;
$orangeOnBlack: #fe7b51;
$yellow: #f3c748;
$light-grey: #f1f1f1;
$off-white: #dddddd;
$white: #ffffff;
$black: #000000;
$bg-grey: #1b1b1b;
$throbber-black: #3D4250;

$off-grey: #f4f4f4;
$off-black: #202020;
$divide-grey: #c4c4c4;

$font-title: "abc-monument-grotesk";
$font-body: "Inter", sans-serif;

// fonts sizes
$font-base: 16px;
$font-size-xxl: 9.375rem; // 150px
$font-size-xl: 6rem; // 96px
$font-size-lgx: 5rem; // 80px
$font-alt-lgx: 4.5rem; // 72px
$font-alt-md: 4rem; // 64px
$font-size-lg: 3.5rem; // 56px
$font-size-md: 2.5rem; // 40px
$font-size-sm: 2.25rem; // 36px
$font-size-xsm: 2rem; // 32px
$font-size-xs: 0.9375rem; // 15px
$font-size-xxs: 0.75rem; // 14px

$font-size-hero--landing: 10rem; // 160px
$font-size-heading-lg: 8.875rem; // 142px
$font-size-heading-md: 6.875rem; // 110px

$font-size-sub: 1.25rem; // 20px

$font-size-body: 1.6875rem; // 27px
$font-size-job-title: 1.25rem; // 20px

// spacing
$spacing-8: 8px;
$spacing-16: 16px;
$spacing-24: 24px;
$spacing-32: 32px;
$spacing-64: 64px;

// radius
$radius-sm: 20px;
$radius: 70px;

// letter spacing
$letter-spacing-lg: 0.08em;
$letter-spacing: -0.01em;

// line-height
$line-height-xl: 125%;
$line-height-lg: 120%;
$line-height: 100%;
$line-height-md: 90.4%;

// Box shadows
$card-shadow: 0px 258px 103px rgba(49, 2, 26, 0.01),
0px 145px 87px rgba(49, 2, 26, 0.05), 0px 64px 64px rgba(49, 2, 26, 0.09),
0px 16px 35px rgba(49, 2, 26, 0.1), 0px 0px 0px rgba(49, 2, 26, 0.1);
$card-shadow--simple: 13px 22px 21px rgba(49, 2, 26, 0.2);
$card-shadow--columns: 0 4px 41px rgba(49, 2, 26, 0.2);

// transitions
$transition-speed: 300ms;
$transition-easing: ease-in-out;

$path-images: "../../assets/images";
$path-icons: "#{$path-images}/icons";

// grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xlg: 1550px
);
