footer {
  background-color: $black;
  color: $white;
  padding: 40px 0 24px;
  text-align: left;
  overflow: hidden;

  .logo {
    max-width: 157px;
    margin-bottom: 56px;
  }

  .col-header {
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: $letter-spacing-lg;
    line-height: $line-height-xl;
  }

  .copyright {
    text-align: right;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: $letter-spacing-lg;
    line-height: $line-height-xl;
    margin-top: 76px;
  }

  .social {
    margin-top: $spacing-16;

    &__list-item {
      display: inline-block;
      margin-right: $spacing-8;
    }

    &__list-link {
      svg {
        path {
          transition: fill $transition-easing $transition-speed;
        }
      }

      &:hover,
      &:active {
        svg {
          path {
            fill: $pink;
          }
        }
      }
    }
  }

  .container {
    position: relative;
  }

  .image-top {
    display: none;
  }

  .image-bottom {
    position: absolute;
    bottom: -100px;
    left: -20px;
    max-width: 80px;
  }
}

@include media-breakpoint-up(md) {
  footer {
    padding: 152px 0 40px;
    position: relative;

    .image-top {
      position: absolute;
      display: block;
      max-width: 338px;
      top: 0;
      right: 20px;
    }

    .image-bottom {
      max-width: 122px;
      left: 100px;
      bottom: -112px;
    }

    .col-header {
      font-size: $font-base;
    }

    .copyright {
      font-size: $font-base;
    }

    address {
      font-size: $font-size-body;
    }
  }
}
