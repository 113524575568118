.slick-slider {
  margin-bottom: 70px;
  display: flex !important;

  .slick-track {
    display: flex;
    height: 100%;
  }

  .slick-list {
    margin-right: -1.5rem;
    width: 100%;
  }

  .slick-dots {
    li,
    button,
    button:before {
      width: 16px;
      height: 16px;
    }

    li.slick-active button:before {
      color: $white;
      opacity: 1;
    }

    button:before {
      font-size: 1rem;
      line-height: 16px;
      opacity: 1;
      color: $pink;
    }
  }
}

.slick-initialized .slick-slide {
  display: flex !important;

  > div {
    display: flex;
    height: 100%;

    > div {
      display: flex;
    }
  }
}

@include media-breakpoint-up(xl) {
  .slick-slider {
    margin-bottom: 0;
  }
}
