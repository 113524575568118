article {
  .article__body {
    max-width: 884px;
    margin: 42px auto;
  }
}

@include media-breakpoint-up(md) {
  article {
    p {
      margin-bottom: 30px;

      h3 {
        margin-bottom: 10px;
      }
    }

    &.article {
      &--first {
        .article__body {
          position: relative;

          &::before,
          &::after {
            position: absolute;
            content: "";
            background-repeat: no-repeat;
            background-position: center;
          }

          &::before {
            left: -170px;
            top: -70px;
            background-image: url("#{$path-images}/icons/static-star.svg");
            width: 128px;
            height: 121px;
          }

          &::after {
            top: 100%;
            right: -120px;
            background-image: url("#{$path-images}/icons/unicycle.svg");
            width: 177px;
            height: 175px;
          }
        }
      }

      &--second {
        .article__body {
          position: relative;

          &::before,
          &::after {
            position: absolute;
            content: "";
            background-repeat: no-repeat;
            background-position: center;
            height: 100%;
          }

          &::before {
            left: -230px;
            bottom: 50px;
            background-image: url("#{$path-images}/icons/static-star.svg");
            width: 128px;
            height: 121px;
          }

          &::after {
            top: 50%;
            right: -340px;
            transform: translateY(-50%);
            background-image: url("#{$path-images}/icons/invent.svg");
            width: 220px;
            height: 290px;
          }
        }
      }
    }

    .article__body {
      margin: 160px auto;

      div {
        &:last-child {
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
