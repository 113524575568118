.btn {
  font-family: $font-title;
  font-weight: 900;
  display: inline-block;
  padding: $spacing-16 $spacing-32;
  background-color: $black;
  color: $off-grey;
  border-radius: $radius;
  text-transform: uppercase;
  text-decoration: none;
  transition: color $transition-speed $transition-easing,
    background-color $transition-speed $transition-easing;

  &:active,
  &:hover {
    background-color: $off-grey;
    color: $black;
  }

  &--secondary {
    background-color: $off-grey;
    color: $black;

    &:active,
    &:hover {
      background-color: $pink;
      color: $black;
    }
  }

  &--secondary-alt {
    &:active,
    &:hover {
      background-color: $black;
      color: $white;
    }
  }

  &--arrow {
    position: relative;
    padding: $spacing-16 $spacing-64 $spacing-16 $spacing-32;

    &:after {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      right: 22px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url("#{$path-icons}/arrow.svg");
      transition: background-image $transition-easing $transition-speed;
    }

    &:active,
    &:hover {
      &:after {
        background-image: url("#{$path-icons}/arrow--white.svg");
      }
    }
  }
}

.throbber {
  background-color: $off-white;
  border: 2px solid $throbber-black;
  padding: 16px 12px;
  border-radius: $radius;
  display: inline-block;
  transition: background-color 300ms ease-in-out;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  span {
    display: block;
    border-radius: 100%;
    width: 8px;
    height: 8px;
    opacity: 1;
    background-color: $throbber-black;
    margin-bottom: 2px;
    animation-duration: 1.2s;
    animation-name: fadeOutIn;
    animation-iteration-count: infinite;
    transition: background-color 300ms ease-in-out;

    &:nth-child(2) {
      animation-delay: 200ms;
    }

    &:nth-child(3) {
      animation-delay: 400ms;
    }

    &:nth-child(4) {
      animation-delay: 600ms;
    }
  }

  &:hover,
  &:active {
    background-color: $throbber-black;

    span {
      background-color: $white;
    }
  }
}

@keyframes fadeOutIn {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
