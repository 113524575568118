.quote {
  position: relative;
  display: flex;

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  &__image-top {
    position: absolute;
    top: -88px;
    left: -30px;
    max-width: 91px;
  }

  &__image-bottom {
    position: absolute;
    bottom: -73px;
    right: 0;
    max-width: 61px;
  }
}

@include media-breakpoint-up(md) {
  .quote {
    &__image-top {
      top: -65px;
      left: 59px;
      max-width: 144px;
    }

    &__image-bottom {
      bottom: -40px;
      right: 145px;
      max-width: 122px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .quote {
    .container {
      padding-right: 0;
      padding-left: 0;
    }

    &.paragraph {
      padding: 274px 0 280px;
    }
  }
}
