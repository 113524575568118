.navigation {
  position: relative;

  &__list {
    margin-bottom: 0;
    display: none;

    &.isOpen {
      display: block;
      position: absolute;
      right: 0;
      top: 80px;
      height: 100vh;

      &::before {
        content: "";
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: $pink;
        z-index: -1;
        top: 0;
        left: 0;
      }

      .navigation__list-item {
        margin-bottom: $spacing-16;
      }
    }
  }

  &__list-item {
    display: inline-flex;
    margin-left: $spacing-16;
  }

  &__link {
    color: $black;
    padding: 10px 16px;
    background-color: $white;
    font-weight: 700;
    border-radius: 70px;
    text-decoration: none;
    transition: background-color $transition-easing $transition-speed,
      color $transition-easing $transition-speed;

    &--bg-light {
      background-color: $off-grey;
    }

    &:active,
    &:hover {
      color: $white;
      background-color: $black;
    }
  }
}

@include media-breakpoint-up(md) {
  .navigation {
    &__list {
      display: block;
    }
  }
}

.hamburger {
  cursor: pointer;
  background-color: $white;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  margin: 0 0 0 auto;
  transition: background-color $transition-easing $transition-speed;

  &--bg-light {
    background-color: $black;

    span {
      background-color: $white !important;
    }

    &:hover {
      background-color: $white;
  
      span {
        background-color: $black;
      }
    }
  }

  &:hover {
    background-color: $black;

    span {
      background-color: $white;
    }
  }

  span {
    position: absolute;
    display: block;
    height: 3px;
    width: 100%;
    max-width: 24px;
    background-color: $black;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    opacity: 1;
    transition: opacity $transition-easing $transition-speed,
      transform $transition-easing $transition-speed,
      top $transition-easing $transition-speed,
      background-color $transition-easing $transition-speed;

    &:nth-child(1) {
      top: 18px;
    }

    &:nth-child(3) {
      top: 30px;
    }
  }

  &.isOpen {
    span {
      &:nth-child(1) {
        top: 24px;
        transform: translateY(-50%) translateX(-50%) rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        top: 24px;
        transform: translateY(-50%) translateX(-50%) rotate(-45deg);
      }
    }
  }
}
