// Regular: 700
@font-face {
  font-family: "abc-monument-grotesk";
  src: local("abc-monument-grotesk"),
    url("../../assets/fonts/ABCMonumentGrotesk-Regular.otf") format("otf"),
    url("../../assets/fonts/ABCMonumentGrotesk-Regular.woff2") format("woff2"),
    url("../../assets/fonts/ABCMonumentGrotesk-Regular.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// Heavy: 800
@font-face {
  font-family: "abc-monument-grotesk";
  src: local("abc-monument-grotesk"),
    url("../../assets/fonts/ABCMonumentGrotesk-Heavy.otf") format("otf"),
    url("../../assets/fonts/ABCMonumentGrotesk-Heavy.woff2") format("woff2"),
    url("../../assets/fonts/ABCMonumentGrotesk-Heavy.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

// Heavy:
@font-face {
  font-family: "abc-monument-grotesk";
  src: local("abc-monument-grotesk"),
    url("../../assets/fonts/ABCMonumentGrotesk-Ultra.otf") format("otf"),
    url("../../assets/fonts/ABCMonumentGrotesk-Ultra.woff2") format("woff2"),
    url("../../assets/fonts/ABCMonumentGrotesk-Ultra.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
