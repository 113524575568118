.disable-scroll {
  overflow: hidden;
  max-height: 100vh;
}

.text {
  &--orange {
    color: $orange;
  }

  &--pink {
    color: $pink;
  }

  &--purple {
    color: $purple;
  }

  &--orangeonblack {
    color: $orangeOnBlack;
  }

  &--underline {
    text-decoration: underline;
  }
}

.constricted {
  max-width: 884px;
  margin: 42px auto;

  &--sides-only {
    margin: 0 auto;
  }
}

.divide-pip {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 52px;
    height: 6px;
    background-color: $divide-grey;
  }
}

.title-lower {
  text-transform: lowercase;

  &:first-letter {
    text-transform: capitalize;
  }
}

.title-initial {
  text-transform: initial;
}

.eyebrow {
  font-family: $font-title;
  font-weight: 400;
  margin-bottom: 32px;
  text-transform: uppercase;
  font-size: $font-size-xs;
  letter-spacing: 0.08em;
}

@include media-breakpoint-up(md) {
  .constricted {
    margin: 160px auto;

    &--sides-only {
      margin: 0 auto;
    }
  }
}
