.people {
  &__text {
    margin-bottom: 48px;
  }

  &__list-item {
    display: inline-block;
    width: 48%;
  }

  img {
    max-width: 145px;
    margin-bottom: $spacing-32;
  }

  &__job-title {
    color: $orange;
    font-size: $font-base;
  }

  &__name {
    margin-bottom: 4px;
  }
}

@include media-breakpoint-up(md) {
  .people {
    &__text {
      margin-bottom: 96px;
    }

    &__list {
      display: flex;
      margin-bottom: 96px;
    }

    &__list-item {
      &:not(&:last-child) {
        margin-right: $spacing-24;
      }
    }

    &__job-title {
      font-size: $font-size-job-title;
    }

    img {
      max-width: 169px;
    }
  }
}
